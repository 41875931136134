<template lang="pug">
  div
    .widget
      .widget-header
        h1.title Cities
        i.la.la-2x.la-filter.d-flex.align-items-center.mobile(@click="areFiltersOpened = !areFiltersOpened")
      .widget-body
        filters(@submit="loadData()", :default-filters="defaultFilters", :current-filters="filters", :page-name="'cities'", :is-opened="areFiltersOpened", @close-filters="areFiltersOpened = false")
          .form-row-main
            .form-group.has-feedback
              label Search
              input.form-control.input-search(type="text", placeholder="Search...", v-model="filters.keyword")
              span.form-control-feedback.la.la-search
            .form-group
              label Country
              multiselect(:multiple="false", :options="countryOptions", v-model="filters.country",
                track-by="code", label="name", deselect-label="", select-label="",
                @input="selectCountry()")
            .form-group
              label Region
              multiselect(:multiple="false", :options="regionOptions", v-model="filters.region",
                track-by="id", label="name", deselect-label="", select-label="")
            .form-group.no-label
              button.btn.btn-primary(type="button", @click="loadData()") Go

        p.clearfix &nbsp;

        .ov-table-wrapper
          table.table.table-bordered.table-sm.table-hover.table-responsive-sm
            thead
              tr
                th
                  .text ID
                th
                  .text Name
                th
                  .text Country
                th
                  .text Region
            tbody
              tr(v-if="filteredRecords.length === 0")
                td(colspan="4") No matching records were found
              tr(v-for="r in filteredRecords", :key="r.id")
                td {{ r.id }}
                td {{ r.name }}
                td {{ r.country_code }}
                td [{{ r.region_id }}] {{ r.region_name }}


</template>
<script>
import Vue from 'vue';

export default {
  name: 'CityList',
  async beforeRouteEnter(to, from, next) {
    let countryOptions = await Vue.ovData.country.getOptions();
    next((vm) => {
      vm.countryOptions = countryOptions;
      return vm;
    });
  },
  computed: {
    filteredRecords: function () {
      if (!this.filters.keyword) {
        return this.records;
      }
      let kw = this.filters.keyword.toLowerCase();
      return this.records.filter((r) => {
        return r.name.toLowerCase().match(kw) || r.id == kw;
      });
    },
  },
  data() {
    const defaultFilters = {
      keyword: '',
      region: 0,
      country: null,
    };
    return {
      areFiltersOpened: false,
      busy: false,
      countryOptions: [],
      regionOptions: [],
      defaultFilters,
      filters: { ...defaultFilters },
      records: [],
    };
  },
  methods: {
    async selectCountry() {
      if (!this.filters.country) {
        Vue.set(this.regionOptions, []);
        return;
      }

      let regionOptions = await this.$ovData.region.getOptions(true, this.filters.country.code);
      this.regionOptions = regionOptions;
      //Vue.set(this.regionOptions, this.filters.country.code, regionOptions);
    },

    async loadData() {
      if (!this.filters.country) {
        this.$ovNotify.error('Please select a country');
        return;
      }
      this.busy = true;
      let params = {
        page: 1,
        page_size: 10000,
        country: this.filters.country.code,
        region_id: this.filters.region ? this.filters.region.id : 0,
      };
      try {
        let resp = await this.$ovReq.get('city/getList', { params });
        this.records = resp.records;
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },
  },
  created() {
    // this.loadData();
  },
};
</script>
